<template>
  <section>
    <div>
      <div
        class="card mb-3 profile-card"
        style="width: 100%"
        v-loading.fullscreen.lock="loading"
        element-loading-text="removing your workspace data..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="card-header bg-light">
          <h3 class="title fw-normal mb-0 p-2">Remove Workspace</h3>
        </div>
        <div class="p-10">
          <div>
            <div>Your Password :</div>
            <el-input
              :type="passwordType"
              placeholder="Enter your Password"
              v-model="password"
              :style="'width:30%'"
              autocomplete="off"
              class="input-field"
            >
              <el-button
                :disabled="!password"
                @click="handlePasswordTypeChange"
                slot="append"
              >
                <i
                  :class="
                    passwordType == 'password'
                      ? 'el-icon-view'
                      : 'el-icon-arrow-down'
                  "
                ></i>
              </el-button>
            </el-input>
          </div>
          <div class="mt-1">
            <div>Current Workspace Name (Case Sensitive) :</div>
            <el-input
              :style="'width:30%;border:0.5px solid red;border-radius: 5px;'"
              :class="{ active: isActive }"
              placeholder="Enter Current Workspace Name"
              v-model="workspaceName"
              autocomplete="off"
              @input="handleClass"
              class="input-field"
            >
            </el-input>
          </div>
          <div
            class="mt-1"
            v-if="
              password &&
              workspaceName &&
              childWorkspaces &&
              childWorkspaces.length
            "
          >
            <el-checkbox v-model="removeChildWorkSpaces">
              Would you like to remove child workspaces (i.e., workspaces
              created from this one)
            </el-checkbox>

            <p v-if="removeChildWorkSpaces">Select workspaces to remove</p>
            <el-checkbox
              v-if="removeChildWorkSpaces"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >Select all</el-checkbox
            >
            <el-checkbox-group
              v-model="selectedWorkspaces"
              v-if="removeChildWorkSpaces"
              class="checkbox-group-vertical mt-1"
              @change="handleCheckedWorkspacesChange"
            >
              <el-checkbox
                v-for="item in childWorkspaces"
                :label="item._id"
                :key="item._id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="card-footer bg-white">
          <el-button
            type="primary"
            class="align-right"
            :disabled="checkButtonDisabled()"
            @click="handleRemoveWorkspace"
            ><i class="el-icon-delete"></i> Remove workspace
            permanently</el-button
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import authHelper from "@/mixins/authHelper";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  name: "remove-workspace",
  mounted() {
    this.getInfo();
  },
  mixins: [authHelper],
  data() {
    return {
      password: "",
      workspaceName: "",
      loading: false,
      passwordType: "password",
      removeChildWorkSpaces: false,
      childWorkspaces: [],
      selectedWorkspaces: [],
      isIndeterminate: false,
      checkAll: true,
      isActive: false,
    };
  },
  methods: {
    handlePasswordTypeChange() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    getInfo() {
      this.getWorkspaceList.map((comp) => {
        if (
          comp?.company_id?.is_cloned &&
          comp?.company_id?.parent_company_id?.toString() ==
            this.getActiveWorkspace?.company_id?.toString()
        ) {
          this.childWorkspaces.push(comp.company_id);
          this.selectedWorkspaces.push(comp.company_id._id);
        }
      });
    },
    handleRemoveWorkspace() {
      if (this.getCompanyDetails.name !== this.workspaceName) {
        return this.$message.warning("Workspace name mismatched.");
      } else if (
        this.removeChildWorkSpaces &&
        !this.selectedWorkspaces?.length
      ) {
        return this.$message.warning(
          "Please Select at least one child workspace"
        );
      }
      Swal.close();
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to remove the workspace(s)? All your data will be permanently deleted and cannot be recovered.",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.loading = true;
            let params = {
              company_id:
                this.getActiveWorkspace?.company_id ||
                this.getCompanyDetails._id,
              password: this.password,
            };
            if (this.removeChildWorkSpaces) {
              params["childWorkspaces"] = this.selectedWorkspaces;
            }
            let data = await postAPICall("POST", "/remove-workspace", params);
            if (data && !data.success) {
              this.loading = false;
              return this.$notify.error({
                title: "Error",
                message: "Invalid Password",
              });
            }
            this.$notify.success({
              title : "",
              message : "Application removed successfully"
            })
            this.loading = false;
            this.logout();
          } catch (err) {
            console.log("err", err);
            this.loading = false;
            this.$message.warning(
              "Error occurred while removing workspace(s)."
            );
          }
          Swal.close();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //   this.currentWorkspaceId = this.currentWorkspace.company_id;
          //   this.setActiveRole();
        }
      });
    },
    checkButtonDisabled() {
      if (!this.password || !this.workspaceName) {
        return true;
      }
      return false;
    },
    handleClass() {
      this.isActive = this.workspaceName == this.getCompanyDetails.name ? true : false;
    },
    handleCheckAllChange(val) {
      this.selectedWorkspaces = val
        ? this.childWorkspaces.map((e) => e._id)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedWorkspacesChange() {
      let checkedCount = this.selectedWorkspaces?.length;
      this.checkAll = checkedCount === this.childWorkspaces?.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.childWorkspaces.length;
    },
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getWorkspaceList",
      "getAuthenticationDetails",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
  },
};
</script>
<style scoped>
.checkbox-group-vertical .el-checkbox {
  display: block;
}
.card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}

.align-right {
  margin-left: auto;
}
.active {
  border: 0.5px solid green !important;
  border-radius: 5px;
}
</style>