var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"card mb-3 profile-card",staticStyle:{"width":"100%"},attrs:{"element-loading-text":"removing your workspace data...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_vm._m(0),_c('div',{staticClass:"p-10"},[_c('div',[_c('div',[_vm._v("Your Password :")]),_c('el-input',{staticClass:"input-field",style:('width:30%'),attrs:{"type":_vm.passwordType,"placeholder":"Enter your Password","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('el-button',{attrs:{"slot":"append","disabled":!_vm.password},on:{"click":_vm.handlePasswordTypeChange},slot:"append"},[_c('i',{class:_vm.passwordType == 'password'
                    ? 'el-icon-view'
                    : 'el-icon-arrow-down'})])],1)],1),_c('div',{staticClass:"mt-1"},[_c('div',[_vm._v("Current Workspace Name (Case Sensitive) :")]),_c('el-input',{staticClass:"input-field",class:{ active: _vm.isActive },style:('width:30%;border:0.5px solid red;border-radius: 5px;'),attrs:{"placeholder":"Enter Current Workspace Name","autocomplete":"off"},on:{"input":_vm.handleClass},model:{value:(_vm.workspaceName),callback:function ($$v) {_vm.workspaceName=$$v},expression:"workspaceName"}})],1),(
            _vm.password &&
            _vm.workspaceName &&
            _vm.childWorkspaces &&
            _vm.childWorkspaces.length
          )?_c('div',{staticClass:"mt-1"},[_c('el-checkbox',{model:{value:(_vm.removeChildWorkSpaces),callback:function ($$v) {_vm.removeChildWorkSpaces=$$v},expression:"removeChildWorkSpaces"}},[_vm._v(" Would you like to remove child workspaces (i.e., workspaces created from this one) ")]),(_vm.removeChildWorkSpaces)?_c('p',[_vm._v("Select workspaces to remove")]):_vm._e(),(_vm.removeChildWorkSpaces)?_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("Select all")]):_vm._e(),(_vm.removeChildWorkSpaces)?_c('el-checkbox-group',{staticClass:"checkbox-group-vertical mt-1",on:{"change":_vm.handleCheckedWorkspacesChange},model:{value:(_vm.selectedWorkspaces),callback:function ($$v) {_vm.selectedWorkspaces=$$v},expression:"selectedWorkspaces"}},_vm._l((_vm.childWorkspaces),function(item){return _c('el-checkbox',{key:item._id,attrs:{"label":item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"card-footer bg-white"},[_c('el-button',{staticClass:"align-right",attrs:{"type":"primary","disabled":_vm.checkButtonDisabled()},on:{"click":_vm.handleRemoveWorkspace}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" Remove workspace permanently")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header bg-light"},[_c('h3',{staticClass:"title fw-normal mb-0 p-2"},[_vm._v("Remove Workspace")])])
}]

export { render, staticRenderFns }